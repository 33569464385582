// New CardComponent
import type { RFC } from 'common-web'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card'

export const BetterCard: RFC<{ title?: string; description?: string; footer?: any }> = ({
  title = undefined,
  description,
  children,
  footer,
  className,
  ...props
}) => (
  <Card className={className} {...props}>
    {(title || description) && (
      <CardHeader>
        {title && <CardTitle>{title}</CardTitle>}
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
    )}
    {children && <CardContent>{children}</CardContent>}
    {footer && (
      <>
        <hr className="mb-4" />
        <CardFooter>{footer}</CardFooter>
      </>
    )}
  </Card>
)
