import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'
import { Link, useNavigate } from '@remix-run/react'
import { PlusIcon } from 'lucide-react'
import { Spinner } from './spinner'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'bg-background text-foreground hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
  },
)
Button.displayName = 'Button'

const LinkButton = React.forwardRef<HTMLButtonElement, ButtonProps & { to: string }>(
  ({ className, to, variant, size, children, ...props }, ref) => {
    const navigte = useNavigate()
    const handleClick = () => navigte(to)

    return (
      <Button
        className={cn('cursor-pointer', buttonVariants({ variant, size, className }))}
        ref={ref}
        asChild={true}
        onClick={handleClick}
        {...props}
      >
        <Link to={to}>{children}</Link>
      </Button>
    )
  },
)

LinkButton.displayName = 'LinkButton'

const PlusButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    return (
      <Button className={cn('', className)} variant={variant} size={size} asChild={asChild} ref={ref} {...props}>
        <div className="flex items-center">
          <PlusIcon className="mr-2 h-5 w-5" />
          {props.children}
        </div>
      </Button>
    )
  },
)

PlusButton.displayName = 'PlusButton'

const PlusLinkButton = React.forwardRef<HTMLButtonElement, ButtonProps & { to: string }>(
  ({ className, to, variant, size, children, ...props }, ref) => {
    const navigte = useNavigate()
    const handleClick = () => navigte(to)
    return (
      <PlusButton
        className={cn('cursor-pointer', buttonVariants({ variant, size, className }))}
        ref={ref}
        asChild={true}
        {...props}
        onClick={handleClick}
      >
        <Link to={to}>{children}</Link>
      </PlusButton>
    )
  },
)

LinkButton.displayName = 'LinkButton'

const LoadingButton = React.forwardRef<HTMLButtonElement, ButtonProps & { isLoading: boolean; loadingText: string }>(
  ({ isLoading = false, loadingText, className, ...props }, ref) => {
    return (
      <Button ref={ref} className={className} disabled={isLoading} {...props}>
        {isLoading ? (
          <div className="flex space-x-2 items-center">
            <Spinner /> <div>{loadingText}</div>
          </div>
        ) : (
          props.children
        )}
      </Button>
    )
  },
)

export { Button, buttonVariants, LoadingButton, PlusButton, LinkButton, PlusLinkButton }
