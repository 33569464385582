import { authenticator } from '@/.server/auth'
import { commitSession, getSession } from '@/.server/session'
import { BetterCard, useTitle } from '@/components'
import { Button, FormGroup, Input, LinkButton } from '@/components/ui'
import { AUTH_STRATEGIES } from '@/lib/auth-strategy-name'
import { Label } from '@radix-ui/react-label'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { Form, Link, json, redirect, useLoaderData } from '@remix-run/react'
import { useAssetUrl } from 'common-web/src/apex'

// First we create our UI with the form doing a POST and the inputs with the
// names we are going to use in the strategy
export default function Screen() {
  useTitle('Login')

  const googleLogoUrl = useAssetUrl('/icons/google.svg')
  const githubLogoUrl = useAssetUrl('/icons/github.svg')
  const loaderData = useLoaderData<typeof loader>()

  return (
    <BetterCard
      className="w-full mx-auto max-w-sm"
      title="Login"
      description="Login with your email and password below."
      footer={
        <LinkButton variant={'ghost'} to="/auth/signup" className="w-full font-normal">
          <span>Don't have an account?</span>&nbsp;<span className="underline">Sign up</span>
        </LinkButton>
      }
    >
      <Form method="post">
        <div className="space-y-md">
          {loaderData?.error && (
            <Label className="text-red-500">{loaderData.error.message || 'An error occurred while logging in'}</Label>
          )}
          <FormGroup label="Email" autoFocus={true} component={Input} name="email" type="email" />
          <FormGroup
            component={Input}
            label="Password"
            labelRight={
              <Link tabIndex={-1} to="/auth/reset" className="text-sm leading-none select-none underline">
                Forgot your password?
              </Link>
            }
            type="password"
            name="password"
            autoComplete="current-password"
            required
          />
          <Button type="submit" className="w-full">
            Login
          </Button>
        </div>
      </Form>
      <div className="space-y-md mt-4">
        <Form method="post" action="/auth/login/google">
          <input type="hidden" name="strategy" value="google" />
          <Button type="submit" variant={'outline'} className="w-full flex space-x-2">
            <img src={googleLogoUrl} className="w-4 h-4" />
            <div>Continue with Google</div>
          </Button>
        </Form>
        <Form method="post" action="/auth/login/github">
          <Button type="submit" variant={'outline'} className="w-full flex space-x-2">
            <input type="hidden" name="strategy" value="github" />
            <img src={githubLogoUrl} className="w-4 h-4" />
            <div>Continue with Github</div>
          </Button>
        </Form>
      </div>
    </BetterCard>
  )
}

/**
 * This also applicable in the _auth.register route action function
 */
export async function action({ request }: ActionFunctionArgs) {
  const user = await authenticator.authenticate(AUTH_STRATEGIES.credentials, request, {
    // successRedirect: '/',
    failureRedirect: '/auth/login',
  })

  const session = await getSession(request.headers.get('cookie'))
  session.set(authenticator.sessionKey, user)
  const headers = new Headers({ 'Set-Cookie': await commitSession(session) })
  return redirect('/', { headers })
}

// Finally, we can export a loader function where we check if the user is
// authenticated with `authenticator.isAuthenticated` and redirect to the
// dashboard if it is or return null if it's not
export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/',
  })
  const session = await getSession(request.headers.get('Cookie'))
  const error = session.get(authenticator.sessionErrorKey)
  return json({ error })
}
