import { cn } from '@/lib/utils'
import React from 'react'
import type { ComponentProps } from 'react'
import { Input } from './input'
import { Label } from './label'

type FieldProps<C extends React.ElementType> = {
  name: string
  label?: string
  className?: any
  description?: string
  placeholder?: string
  type?: string
  required?: boolean
  component?: C
  labelRight?: any
} & ComponentProps<C>

export const FormGroup = <C extends React.ElementType = typeof Input>({
  name,
  label,
  labelRight,
  description,
  message,
  component: Component = Input,
  className = undefined,
  ...props
}: FieldProps<C>) => (
  <div className={cn('form-group space-y-2')}>
    <div className="flex items-center justify-between">
      <Label>{label}</Label>
      {labelRight}
    </div>
    <div className="form-control">
      <Component {...props} className={className} name={name} />
    </div>
    <div className="form-description text-sm text-muted-foreground">{description}</div>
    <div className="form-message">{message}</div>
  </div>
)

export const FormGroupWithChildren = <C extends React.ElementType = typeof Input>({
  label,
  labelRight,
  description,
  message,
  className = undefined,
  children,
  ...props
}: Omit<FieldProps<C>, 'name'>) => (
  <div className={cn('form-group space-y-2', className)} {...props}>
    <div className="flex items-center justify-between">
      <Label>{label}</Label>
      {labelRight}
    </div>
    <div className="form-control">{children}</div>
    <div className="form-description text-sm text-muted-foreground">{description}</div>
    <div className="form-message">{message}</div>
  </div>
)
