export const apex =
  // Hack for now.
  import.meta.env.VITE_CENTER_APEX ||
  process.env.VITE_CENTER_APEX ||
  import.meta.env.CENTER_APEX ||
  process.env.CENTER_APEX

interface Params {
  subdomain?: string
  path?: string
  query?: Record<string, string>
  hash?: string
  protocol?: 'https:' | 'wss:'
}

export const getAbsoluteUrl = (params: Params) => {
  const { subdomain, path, query, hash } = params
  const protocol = params.protocol || 'https:'
  const url = new URL(`${protocol}//${apex}`)

  if (subdomain) {
    url.hostname = `${subdomain}.${url.hostname}`
  }
  if (path) {
    url.pathname = path
  }
  if (query) {
    Object.keys(query).forEach((key) => url.searchParams.append(key, query[key]))
  }
  if (hash) {
    url.hash = hash
  }
  return url.toString()
}

export const getAssetUrl = (params: Omit<Params, 'subdomain'>) => getAbsoluteUrl({ subdomain: 'assets', ...params })
