import { createWSClient, httpLink, loggerLink, splitLink, wsLink } from '@trpc/client'
import type { TRPCClient, TRPCWebSocketClient } from '@trpc/react-query'
import superjson from 'superjson'
import { trpc } from './trpc'

const currentDomain = window.location.hostname
console.debug('Current Domain:', currentDomain)
const wsURL = `wss://${currentDomain}/trpc`
console.debug('wsUrl', wsURL)

// getAbsoluteUrl({ protocol: 'wss:', subdomain: 'dash', path: '/trpc' })

export { type ChainpipeApiRouter } from 'chainpipe-api/src/trpc/_app'

// @ts-ignore
const wsClient: TRPCWebSocketClient = createWSClient({
  url: wsURL,
  retryDelayMs(attemptIndex) {
    if (attemptIndex > 2) {
      return 5000
    }
    return 1000
  },
})

// @ts-ignore
export const trpcClient: TRPCClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: () => true,
      // enabled: (opts) =>
      // process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
    }),

    splitLink({
      condition(op) {
        return op.type === 'subscription'
      },
      true: wsLink({ client: wsClient }),

      false: httpLink({
        url: '/trpc',
        headers(_ctx) {
          const headers: Record<string, any> = {}
          const _authToken: string | undefined = undefined
          return headers
        },
      }),
    }),
  ],

  transformer: superjson,
})

export type InferLoaderData<T extends (...args: any) => any> = Awaited<ReturnType<T>>
