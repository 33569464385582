import { createContext, useContext } from 'react'

export const ApexContext = createContext({
  apex: 'center.app',
})

export const useApex = () => {
  const { apex } = useContext(ApexContext)
  return apex
}

export const getFullUrl = (params: { subdomain?: string; apex: string; path?: string }) => {
  const path = ['https://']
  if (params.subdomain) {
    path.push(`${params.subdomain}.`)
  }
  path.push(params.apex)
  if (params.path) {
    if (params.path.startsWith('/')) {
      path.push(params.path)
    } else {
      path.push(`/${params.path}`)
    }
  }
  return path.join('')
}

export const useApexUrl = (params: { subdomain?: string; path?: string } = {}) => {
  const apex = useApex()
  return getFullUrl({ subdomain: params.subdomain, path: params.path, apex })
}

export const getAssetUrl = ({ apex, path }: { apex: string; path: string }) =>
  getFullUrl({ subdomain: 'assets', apex, path })

export const useAssetUrl = (path: string) => {
  return useApexUrl({ subdomain: 'assets', path })
}
