import { type RouterOutputs, trpc } from '@/trpc/trpc'
import { useLocation, useNavigate } from '@remix-run/react'
import { chainpipeConfig } from 'common'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { type RFC, betterToast, useCenterAbsoluteUrl } from 'ui'
import { useCookieState } from 'use-cookie-state'

type ProjectType = Exclude<RouterOutputs['auth']['whoami']['projects'][number], null>
type IProjectCreateOutput = RouterOutputs['dashboard']['projects']['create']

export const ProjectContext = createContext<{
  projectId: ProjectType['id']
  setProjectId: (val: ProjectType['id']) => void
  id: ProjectType['id']
  projects: Array<ProjectType>
  project: ProjectType | null
  isWithinProjectGate: true
  refetch: () => void
}>({
  isWithinProjectGate: true,
  project: null,
  projects: [],
  projectId: '',
  // just a shortcut so you don't have to write projectid every time
  id: '',
  refetch: () => null,
  setProjectId: () => null,
})

/**
 * Requires an active project, or forces a reidrect to the project creation screen
 */
export const ProjectGate: RFC = ({ children }) => {
  const auth = trpc.auth.whoami.useQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const [projectIdCookie, setProjectIdCookie] = useCookieState<ProjectType['id'] | null>(
    chainpipeConfig.projectCookieName,
    null,
  )

  const [projects, setProjects] = useState<ProjectType[]>([])

  const newProjectUrl = useCenterAbsoluteUrl({
    subdomain: 'dashboard',
    path: '/projects/new',
  })

  const project = useMemo(() => {
    const foundProject = projects.find((proj) => proj.id === projectIdCookie)
    if (foundProject) return foundProject
    /**
     * If there isn't a found project we reset our state to choose the first one
     */
    if (!foundProject && projects.length > 0) {
      setProjectIdCookie(projects[0].id)
    }
    return foundProject
  }, [projectIdCookie, projects])

  const setProjectId = useCallback(
    (id: ProjectType['id']) => {
      if (!id) {
        throw new Error('invalid id')
      }
      setProjectIdCookie(id)
    },
    [setProjectIdCookie],
  )

  useEffect(() => {
    if (auth.isSuccess) {
      if (auth.data.projects.length === 0 && pathname !== '/projects/new' && !pathname.includes('invitations')) {
        navigate('/projects/new')
      }
      if (auth.data.projects) {
        setProjects(auth.data.projects)
      }

      if (!projectIdCookie && auth.data?.projects?.length > 0) {
        // Set the first project as the active one if none is selected
        setProjectIdCookie(auth.data.projects[0].id)
      }
    }
  }, [
    auth.isSuccess,
    auth.isInitialLoading,
    auth.data?.projects,
    projectIdCookie,
    setProjectIdCookie,
    newProjectUrl,
    setProjects,
    projectIdCookie,
  ])

  if (auth.isInitialLoading) {
    return <div>Loading</div>
  }

  if (!project) {
    if (location.pathname === '/projects/new' || location.pathname.includes('/invitations')) {
      return children
    } else {
      return <div>No Project {pathname}</div>
    }
  }

  return (
    <ProjectContext.Provider
      value={{
        projectId: projectIdCookie as string,
        id: projectIdCookie as string,
        setProjectId,
        project,
        isWithinProjectGate: true,
        projects: auth.data?.projects || [],
        refetch: auth.refetch,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

/**
 * This must be called from within the project gate
 */
export const useProject = () => {
  const ctx = useContext(ProjectContext)
  if (!ctx.isWithinProjectGate) {
    throw new Error('Must be called from within ProjectGate')
  }

  if (!ctx.project) {
    throw new Error('Project required')
  }
  return ctx.project
}

export const useProjectId = () => {
  const project = useProject()
  if (!project || !project.id) {
    throw new Error('no project id')
  }
  return project.id
}

export const useHasProject = () => {
  const ctx = useContext(ProjectContext)
  if (!ctx.isWithinProjectGate) {
    throw new Error('Must be called from within ProjectGate')
  }
  return !!ctx.project
}

export const useProjects = () => {
  const auth = trpc.auth.whoami.useQuery()
  return useMemo(() => auth.data?.projects || [], [auth?.data])
}

/**
 * This is a special purpose utility which serves to deal with the complexities of creating
 * a new project, particularly when there the user starts out without any project
 */
export function useNewProjectCreation() {
  const auth = trpc.auth.whoami.useQuery()
  const navigate = useNavigate()
  const [_, setProjectIdCookie] = useCookieState<ProjectType['id'] | null>(chainpipeConfig.projectCookieName, null)

  return {
    handleProjectCreatedSuccess: async (newProject: IProjectCreateOutput) => {
      betterToast.success('Project created')
      setProjectIdCookie(newProject.id)
      await auth.refetch()
      navigate('/')
    },
    handleProjectCreatedFailure: async (_err: any) => {
      betterToast.error('An error occurred creating the project')
      await auth.refetch()
    },
  }
}
