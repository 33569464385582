import { useEffect } from 'react'

const titleAfter = 'Center'

export function useTitle(title: string | null) {
  useEffect(() => {
    if (title) {
      document.title = `${title} | ${titleAfter}`
    } else {
      document.title = titleAfter
    }
  }, [title])
}
