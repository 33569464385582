import { Outlet } from '@remix-run/react'
import { CenterLogo } from 'ui'

export default function AuthLayout() {
  return (
    <div className="bg-neutral-50 h-screen w-full flex flex-col justify-center space-y-lg items-center">
      <CenterLogo className="w-24 h-24" />
      <Outlet />
    </div>
  )
}
