import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import React, { useContext } from 'react'

export const BreadcrumbContext = React.createContext([] as Array<{ component: any }>)

export function Breadcrumbs() {
  const links = useContext(BreadcrumbContext)
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {links.map((link, index) => (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">{link.component}</BreadcrumbLink>
            </BreadcrumbItem>
            {index < links.length - 1 ? <BreadcrumbSeparator /> : null}
          </>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
