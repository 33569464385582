import { trpc } from '@/trpc/trpc'
import { trpcClient } from '@/trpc/trpc-client.client'
import type { LinksFunction } from '@remix-run/node'
import { useMatches, useRouteError } from '@remix-run/react'
import { Links, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { QueryClientProvider } from '@tanstack/react-query'
// import { footerHeight } from 'ui/src/components/footer'
// import { TooltipProvider } from './components/ui/tooltip'
import { useCenterTrpcClient } from 'common-web'
import { BetterToaster as Toaster } from 'ui'
import { BreadcrumbContext } from './components/breadcrumbs'
import { TooltipProvider } from './components/ui'
import styles from './styles/tailwind.css?url'
import { getAssetUrl } from './utils/apex'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  // ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
  },
  {
    rel: 'icon',
    href: getAssetUrl({ path: 'favicon.ico' }),
  },
]

export function Layout({ children }: { children: React.ReactNode }) {
  const matches = useMatches()
  const breadcrums = matches
    .filter((item) => item.handle)
    .map((item) => ({
      // @ts-ignore
      component: item.handle.breadcrumb(),
    }))
  const queryClient = useCenterTrpcClient({
    onError: (err) => {
      console.error(err.title || err.message || 'Something went wrong.')
    },
  })

  return (
    <html lang="en no-width-scrollbar">
      {/* @ts-ignore */}
      <BreadcrumbContext.Provider value={breadcrums}>
        <head className="min-h-screen">
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
        </head>
        <body className="h-screen">
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <TooltipProvider delayDuration={100}>{children}</TooltipProvider>
              <Toaster />
            </QueryClientProvider>
          </trpc.Provider>
          <ScrollRestoration />
          <Scripts />
        </body>
      </BreadcrumbContext.Provider>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return <div>Something went wrong</div>
}

export default function App() {
  return <Outlet />
}
